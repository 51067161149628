import { Typography } from "@mui/material";
import { useContext } from "react";

import { AppContext } from "@/app";
import OpenWork from "@/components/OpenWork";
import useRemoteConfig from "@/utils/hooks/useRemoteConfig";
import { ActionBar, ActionBarSocial } from "@/views";
import "./Home.scss";

export default function Home() {
  const { isOpenWork } = useContext(AppContext);
  const { config } = useRemoteConfig();

  return (
    <div id="home">
      <section id="home-intro" className="force-center">
        <span>
          <Typography variant="h4">Hi there</Typography>
          <Typography variant="h2" fontWeight={800}>
            I am Albert,
          </Typography>
          <Typography variant="h2" fontWeight={800}>
            a software engineer
          </Typography>
          {isOpenWork && <OpenWork />}
        </span>
      </section>

      {config.useActionBar && <ActionBar />}
      <ActionBarSocial />
    </div>
  );
}
