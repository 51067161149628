import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";

const theme = createTheme({
  palette: {
    mode: "light",
  },
});

function Admin() {
  // TODO: Check if user is logged in here
  // TODO: Redirect to login if not logged in
  return (
    <ThemeProvider theme={theme}>
      <div className="h-full w-full">
        <Typography variant="h4">Admin</Typography>
        <Outlet />
      </div>
    </ThemeProvider>
  );
}

export default Admin;
