import { RouterProvider } from "react-router-dom";

import adminRoute from "./routes/admin.route.tsx";
import appRoute from "./routes/app.route";
import { firebaseApp } from "./services/firebase.service.ts";
firebaseApp;

/**
 * Where configs are set
 * - Routing
 * - Config loading
 */
function MainApp() {
  const isAdmin = window.location.hostname.startsWith("admin.");

  if (isAdmin) {
    return <RouterProvider router={adminRoute} />;
  }
  return <RouterProvider router={appRoute} />;
}

export default MainApp;
