import useTheme from "../../utils/hooks/useTheme";
export default function DebugView() {
  const theme = useTheme();
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "200px",
        height: "300px",
        backdropFilter: "blur(10px)",
      }}
    >
      {JSON.stringify({
        theme,
      })}
    </div>
  );
}
