import { Typography } from "@mui/material";

import useRemoteConfig from "@/utils/hooks/useRemoteConfig";

export default function AdminSettings() {
  const { config } = useRemoteConfig();

  return (
    <div className="flex flex-col gap-4 h-full w-full bg-red-500">
      <Typography variant="h4">Settings</Typography>

      <div className="table w-full">
        {Object.entries(config).map(([key, value]) => (
          <div key={key} className="table-row">
            <div className="table-cell w-1/2">{key}</div>
            <div className="table-cell w-1/2">
              {typeof value === "object" ? (
                <pre>{JSON.stringify(value, null, 2)}</pre>
              ) : (
                <Typography variant="body1">
                  {value ? "true" : "false"}
                </Typography>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
