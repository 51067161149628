import DebugView from "../DebugView/DebugView";

interface BoundaryViewProps {
  children: React.ReactNode;
}
export default function BoundaryView({ children }: BoundaryViewProps) {
  // Get `IS_DEBUGGING` from the environment variable
  const isdebug = import.meta.env.VITE_IS_DEBUGGING === "true";

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        backdropFilter: "blur(10px)",
      }}
    >
      {children}
      {isdebug && <DebugView />}
    </div>
  );
}
