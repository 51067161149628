import "animate.css";
import React from "react";
import ReactDOM from "react-dom/client";

import "./index.scss";
import MainApp from "./main-app.tsx";

ReactDOM.createRoot(document.getElementById("app")!).render(
  <React.StrictMode>
    <MainApp />
  </React.StrictMode>
);
