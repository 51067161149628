import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";

import SuspenseLoading from "@/components/SuspenseLoading";

const Admin = React.lazy(() => import("@/pages/Admin"));
const AdminSignin = React.lazy(
  () => import("@/pages/Admin/AdminSignin/AdminSignin")
);
const AdminSettings = React.lazy(
  () => import("@/pages/Admin/AdminSettings/AdminSettings")
);

const adminRoute = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<SuspenseLoading />}>
        <Admin />
      </Suspense>
    ),
  },
  {
    path: "/login",
    element: (
      <Suspense fallback={<SuspenseLoading />}>
        <AdminSignin />
      </Suspense>
    ),
  },
  {
    path: "/settings",
    element: (
      <Suspense fallback={<SuspenseLoading />}>
        <AdminSettings />
      </Suspense>
    ),
  },
]);

export default adminRoute;
