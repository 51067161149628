/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { createContext } from "react";
import { Outlet } from "react-router-dom";

import "./app.scss";
import { appTheme } from "./app.theme";
import type { AppProps } from "./types/common.type";
import useRemoteConfig from "./utils/hooks/useRemoteConfig";
import { BoundaryView } from "./views";

export const AppContext = createContext<IAppContext>({});

interface IAppContext extends AppProps {
  setConfig?: (config: AppProps) => void;
}

export function App() {
  const { config, setConfig } = useRemoteConfig();

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <BoundaryView>
        <AppContext.Provider
          value={{
            ...config,
            setConfig,
          }}
        >
          <Outlet />
        </AppContext.Provider>
      </BoundaryView>
    </ThemeProvider>
  );
}
