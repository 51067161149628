import { createTheme } from "@mui/material";

// Declare new variant of MuiButtonBase
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    abtn: true; // Albert's custom Button
    "abtn-circle": true;
    "abtn-outline": true;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    mobileS: true;
  }
}
export const lightTheme = {
  palette: {
    mode: "light",
    primary: {
      main: "#2196F3",
      light: "#6EC6FF",
      dark: "#0069C0",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#BBDEFB",
      contrastText: "#000000",
    },
    background: {
      default: "#FFFFFF",
      paper: "#F5F5F5",
    },
    text: {
      primary: "#000000",
      secondary: "#555555",
    },
  },
};

export const darkTheme = {
  palette: {
    mode: "dark",
    primary: {
      main: "#90CAF9",
      light: "#E1F5FE",
      dark: "#42A5F5",
      contrastText: "#000000",
    },
    secondary: {
      main: "#64B5F6",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#121212",
      paper: "#424242",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#AAAAAA",
    },
  },
};

export const appTheme = createTheme({
  breakpoints: {
    values: {
      mobileS: 0,
      xs: 480,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {},
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "grey",
          margin: "10px 0px",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "abtn", color: "primary" },
          style: {
            backgroundColor: "light-color",
            borderRadius: "4px",
            cursor: "pointer",
            transition: "all 0.2s ease-in-out",
            margin: "5px",
            minHeight: "40px",
            color: "dark-color",
            border: "1px solid transparent",
            "&:hover": {
              borderColor: "dark-color",
              backgroundColor: "white",
            },
          },
        },
        {
          props: { variant: "abtn-circle", color: "primary" },
          style: {
            backgroundColor: "light-color",
            borderRadius: "50%",
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            transition: "all 0.2s ease-in-out",
            margin: "5px",
            minHeight: "40px",
            color: "dark-color",
            border: "1px solid transparent",
            "&:hover": {
              transform: "scale(1.05)",
              color: "primary.main",
            },
          },
        },
        {
          props: { variant: "abtn-outline", color: "primary" },
          style: {
            backgroundColor: "transparent",
            borderRadius: "5px",
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            transition: "all 0.2s ease-in-out",
            margin: "5px",
            minHeight: "40px",
            color: "dark-color",
            border: "1px solid",
            borderColor: "primary.main",
            "&:hover": {
              transform: "scale(1.05)",
              color: "primary.main",
              backgroundColor: "primary.dark",
            },
          },
        },
        {
          props: { variant: "abtn-outline", color: "primary" },
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {},
        input: {
          borderRadius: "124px",
          border: "1px solid #e0e0e0",
        },
      },
    },
  },
});
