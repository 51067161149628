const THEME_KEY = "ab-theme";
const useTheme = () => {
  const theme = localStorage.getItem(THEME_KEY);
  return {
    theme: theme || "light",
    setTheme: (theme: string) => {
      localStorage.setItem(THEME_KEY, theme);
    },
  };
};

export default useTheme;
