import { Input as MuiInput, OutlinedInput, TextField } from "@mui/material";
import {
  type InputProps as MuiInputProps,
  type TextFieldVariants,
} from "@mui/material";
import React from "react";

import styles from "./Input.module.css";

interface InputProps extends MuiInputProps {
  label?: string;
  variant?: TextFieldVariants;
  maxWidth?: boolean;
  className?: string;
  name?: string;
  outlined?: boolean;
  size?: "small" | "medium";
  hidden?: boolean;
  optional?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      name,
      outlined = false,
      hidden,
      variant,
      optional,
      error,
      ...other
    }: InputProps,
    ref
  ) => {
    if (hidden) {
      return null;
    }

    return (
      <div className={styles.InputCtn}>
        {label && (
          <label
            className={name ? `input-label-${name}` : ""}
            htmlFor={`${name ? `input-${name}` : ""}`}
          >
            {label}
            {optional && <span className={styles.Optional}>Optional</span>}
          </label>
        )}

        {outlined ? (
          <OutlinedInput ref={ref} name={name} {...other} />
        ) : (
          <TextField variant={variant} name={name} {...other} />
        )}

        <span className="txt-error-helper">{error?.message}</span>
      </div>
    );
  }
);

export default Input;
