import {
  fetchAndActivate,
  getBoolean,
  getString,
} from "firebase/remote-config";
import { useEffect, useState } from "react";

import { firebaseRemoteConfig } from "@/services/firebase.service";
import type { AppProps } from "@/types/common.type";

const useRemoteConfig = () => {
  const [config, setAppConfig] = useState<AppProps>({
    isOpenWork: false,
    portalUrl: "",
    loadingConfig: false,
    isConfigActivated: false,
    socials: [],
    useActionBar: false,
  });

  useEffect(() => {
    const fetchRemoteConfig = async () => {
      setAppConfig((prev) => ({ ...prev, loadingConfig: true }));
      await fetchAndActivate(firebaseRemoteConfig);
      setAppConfig((prev) => ({
        ...prev,
        isConfigActivated: true,
        loadingConfig: false,
        isOpenWork: getBoolean(firebaseRemoteConfig, "is_open_work"),
        portalUrl: getString(firebaseRemoteConfig, "portal_url"),
        socials: JSON.parse(getString(firebaseRemoteConfig, "socials")),
        useActionBar: getBoolean(firebaseRemoteConfig, "use_action_bar"),
      }));
    };

    fetchRemoteConfig();
  }, []);

  return { config, setConfig: setAppConfig as (config: AppProps) => void };
};

export default useRemoteConfig;
